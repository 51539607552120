import React, { useMemo, useState, useEffect } from 'react';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  AFPTable,
  AFPTableRowAction,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import { useUserDetails } from '../providers/user-details-provider';
import UnassignRoleModal from './unassign-roles-modal';
import canUnassignRole from '../../authorization';
import { getScopesStr } from '../utils/scope-utils';
import { canManageAll } from '../../../../users/authorization';

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
};

const UserRoles = () => {
  const {
    user,
    getUserRoleScopes,
    loadingUserRoleScopes,
    userRoleScopes,
    setFeedbackMessage,
    refetchUserDetails,
    refetchUserRoleScopes,
  } = useUserDetails();
  const { currentUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [order, setOrder] = useState([['scope', 'isCustomerAccount', 'asc']]);

  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const getData = () => {
    getUserRoleScopes({
      variables: {
        userId: user.id,
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [paginationState]);

  const unassignRoleFeedback = async (feedback) => {
    setIsOpen(false);
    if (feedback) {
      const { type, message } = feedback;

      if (type === 'success') {
        // Todo : create a const for string 'success'
        await refetchUserDetails();
        await refetchUserRoleScopes();
      }
      setFeedbackMessage({
        type,
        message,
      });
    }
  };

  const ability = useAppAbility();

  const actionsLabel = {
    view: 'View role',
    unassign: 'Unassign',
  };

  const actionsColumn = {
    getActions(userRole) {
      const { view, unassign } = actionsLabel;

      const actions = [
        {
          icon: 'visibility',
          label: view,
        },
      ];

      // TODO define `ui-utils` constants
      const FLEET_BASE_ROLE_ID = 104;
      if (
        canUnassignRole(ability) &&
        currentUser?.id !== user?.id &&
        userRole.roleId !== FLEET_BASE_ROLE_ID &&
        (canManageAll(ability) || !userRole.scope.isCustomerAccount)
      ) {
        actions.push({
          icon: 'cancel',
          label: unassign,
        });
      }

      return actions;
    },
    execute(operation, userRole) {
      const { view, unassign } = actionsLabel;
      if (operation === view) {
        // TODO: history.push(`/admin/roles/${userRoleScopes.roleId}`);
      }
      if (operation === unassign || operation === userRole) {
        setSelectedRole(userRole);
        setIsOpen(true);
      }
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Role name',
        accessor: 'role.name',
        sortable: false,
      },
      {
        Header: 'Scope',
        accessor: 'scope',
        sortable: false,
        Cell: (props) => {
          /* eslint-disable react/prop-types */
          const { cell } = props;
          const { row } = cell;
          const { original: userRole } = row;
          const { scope } = userRole;
          return getScopesStr(scope, ' / ');
        },
      },
      {
        Header: 'Customer Account Type',
        accessor: 'scope.isCustomerAccount',
        sortable: true,
        Cell: ({ value }) => {
          return value ? 'Leasing' : ' ';
        },
      },
      {
        Header: 'Actions',
        accessor: 'action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => {
          /* eslint-disable react/prop-types */
          const { cell } = props;
          const { row } = cell;
          const { original: userRole } = row;
          const rowActions = actionsColumn.getActions(userRole);
          return (
            <AFPTableRowAction
              actions={rowActions}
              actionTitle="Standard Item Action"
              onSelectAction={(operation) => {
                actionsColumn.execute(operation, userRole);
              }}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <>
      <div data-testid="roles-table-test-id" className="roles-table">
        <AFPTable
          expandable
          fullWidth
          stacked
          testId="roles-table"
          columns={columns}
          defaultSort={order}
          onSort={setOrder}
          data={!loadingUserRoleScopes ? userRoleScopes?.rows || [] : []}
        />
        {loadingUserRoleScopes && <Spinner className="padding-y-9" />}
        {userRoleScopes?.rows?.length > 0 && (
          <Pagination
            fullWidth
            variant="advanced"
            itemsPerPageOptions={[10, 25, 50]}
            itemsCount={userRoleScopes.count}
            itemsPerPage={paginationState.limit}
            currentPage={paginationState.currentPage}
            onPageChange={handlePaginationChange}
          />
        )}
        <UnassignRoleModal
          user={user || {}}
          role={selectedRole || {}}
          isOpen={isOpen}
          feedback={unassignRoleFeedback}
        />
      </div>
      {!Array.isArray(userRoleScopes) ||
        (!userRoleScopes.length && !loadingUserRoleScopes && (
          <EmptyState
            hasBackground
            containerStyles="padding-y-5"
            topText={<strong>No roles assigned yet</strong>}
          />
        ))}
    </>
  );
};

export default UserRoles;
