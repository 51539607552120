import { gql } from '@apollo/client';

export const AttachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    name
    description
    attachmentTypeId
    metadataId
    contentURL
    type
    size
    lastModified
    createdAt
    updatedAt
    createdByUser
    user {
      fullName
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query GetAttachments(
    $filter: AttachmentFilter
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getAttachments(
      filter: $filter
      limit: $limit
      offset: $offset
      order: $order
    ) {
      rows {
        ...AttachmentFields
      }
      count
      hasMore
    }
  }
  ${AttachmentFields}
`;

export const GET_AWS_SIGNED_URL = gql`
  mutation GenerateSignedUrlForDocumentation($input: GenerateSignedUrlRequest!) {
    generateSignedUrlForDocumentation(input: $input) {
    metadataId
    name
    scanStatus
    signedUrl  
    }
  }
`;

export const GET_AWS_SIGNED_READ_URL = gql`
  query GenerateReadSignedURL($fileKey: String!) {
    generateReadSignedURL(fileKey: $fileKey)
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: Float!) {
    deleteAttachment(id: $id)
  }
`;

export const UPDATE_ATTACHMENT_DESCRIPTION = gql`
  mutation UpdateAttachmentDescription($id: Float!, $description: String!) {
    updateAttachmentDescription(id: $id, description: $description) {
      id
      name
      description
      contentURL
      type
      size
      lastModified
      createdAt
      updatedAt
      createdByUser
    }
  }
`;

export const CREATE_DOCUMENTATION_ATTACHMENT = gql`
  mutation CreateDocumentationAttachment($input: DocAttachmentInput!) {
    createDocumentationAttachment(input: $input) {
    attachmentTypeId
    contentURL
      description
      id
      lastModified
      linkedEntities
      name
      size
      type
    }
  }
`;

export const GET_ALL_ATTACHMENT_TYPES = gql`
  query GetAllAttachmentTypes {
    getAllAttachmentTypes {
    attachmentType
    attachmentTypeId
    description
    status  
    }
  }
`;

export const GENERATE_READ_SIGNED_URL = gql`
  query generateReadSignedURL($fileType: String!, $fileKey: String!) {
    generateReadSignedURL(fileType: $fileType, fileKey: $fileKey)
  }
`;

export const GET_DOC_METADATA = gql`
  query GetDocMetadata($id: String!) {
    getDocMetadata(id: $id) {
      name
      fileLocation
      fileMimeType
    }
  }
`;
