import React, { useEffect, useMemo, useState } from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
    SelectDropdown,
    AFPTable,
    EmptyState,
    Button,
    Label,
    useModal,
    connectModal,
    Pagination,
} from '@gsa/afp-component-library';
import UploadDocumentationModal from './upload-document';
import DeleteDocumentModal from './delete-document';
import { useDocumentManagement } from './documentation-management-provider';
import AttachmentDownload from './file-download';

const DocumentationManagementListing = () => {
    const {
        agencies,
        deleteAttachment,
        getAttachments,
        attachments,
        setAgencyCode,
        agencyCode,
        attachmentTypes,
        paginationState,
        setPaginationState,
    } = useDocumentManagement();

    const [organizations, setOrganizations] = useState([]);
    const [fileToDelete, setFileToDelete] = useState(null);

    const uploadNewFileModal = useModal();
    const DisplayUploadFileModal = connectModal(UploadDocumentationModal);

    const deleteFileModal = useModal();
    const DisplayDeleteFileModal = connectModal(DeleteDocumentModal);

    const { currentUser } = useCurrentUser();
    const isSiteAdmin = currentUser?.roles?.some((role) => role.name === 'Site Administrator');
    const isCustomerAdmin = currentUser?.roles?.some((role) => role.name === 'Customer Administrator');
    const displayBtnValidation = isCustomerAdmin || isSiteAdmin;

    useEffect(() => {
        const data = agencies?.getAgenciesByPermission;
        if (data) {
            const agenciesData = data?.map((o) => {
                return { label: o.name, value: o.id }
            });
            setOrganizations(agenciesData);
            if (data?.length === 1) {
                setAgencyCode(data[0]?.id)
                getAttachments({
                    variables: {
                        filter: { agencyCode: agencyCode || data[0]?.id },
                        ...paginationState,
                        order: [['createdAt', 'DESC']],
                    }
                })
            }
        }
    }, [agencies?.getAgenciesByPermission]);


    useEffect(() => {
        if (paginationState) {
            getAttachments({
                variables: {
                    filter: { agencyCode },
                    ...paginationState,
                    order: [['createdAt', 'DESC']],
                }
            })
        }
    }, [paginationState]);

    const getFormattedDate = (cretaedDate) => {
        const date = new Date(cretaedDate);
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        return formattedDate;
    }

    const closeFileDelete = () => {
        deleteFileModal.closeModal();
        setFileToDelete(null)
    }

    const handleFileDelete = async() => {
        await deleteAttachment({variables: {id: fileToDelete}});
        closeFileDelete();
    }

    const handlePaginationChange = (currentPage, itemsPerPage) => {
        const offset = (currentPage - 1) * itemsPerPage;
        setPaginationState({
            limit: itemsPerPage,
            offset,
            currentPage,
            isReset: false,
        });
    };

    const columns = useMemo(() => {
        const documentsColumns = [
            {
                Header: 'Category',
                accessor: 'category',
                // eslint-disable-next-line react/prop-types
                Cell: ({ row: { original } }) => {
                    const category = attachmentTypes?.getAllAttachmentTypes &&
                    attachmentTypes?.getAllAttachmentTypes?.find((o) => o.attachmentTypeId === original.attachmentTypeId)
                    return category?.description || '';
                },
            },
            {
                Header: 'File name',
                accessor: 'fileName',
                // eslint-disable-next-line react/prop-types
                Cell: ({ row: { original } }) => {
                    return (
                      <AttachmentDownload
                      // eslint-disable-next-line react/prop-types
                        name={original?.name}
                      // eslint-disable-next-line react/prop-types
                        metadataId={original?.metadataId}
                      />
                    );
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Date uploaded',
                accessor: 'createdAt',
                // eslint-disable-next-line react/prop-types
                Cell: ({ row: { original } }) => {
                    return getFormattedDate(original.createdAt);
                },
            },
            {
                Header: 'Actions',
                id: 'table-row-action',
                sortable: false,
                headerClassName: 'cell-center',
                cellClassName: 'cell-center',
                // eslint-disable-next-line react/prop-types
                Cell: ({ row: { original } }) => {
                    return (
                      <Button
                        disabled={!displayBtnValidation}
                        label='Delete'
                        leftIcon={{ name: 'close' }}
                        variant='unstyled'
                        type='button'
                        data-testid='delete-button'
                        onClick={() => {
                            // eslint-disable-next-line react/prop-types
                            setFileToDelete(Number(original?.id))
                            deleteFileModal.openModal();
                        }}
                      />
                    );
                },
            },
        ];

        return documentsColumns;

    }, [attachmentTypes?.getAllAttachmentTypes]);


    return (
      <div className='documentation-listing'>
        {organizations && (
          <SelectDropdown
            label={<Label className='agency-dropdown-label'>Show documents for organization:</Label>}
            name='agency'
            options={[{ value: '', label: 'Select agency' }, ...organizations]}
            onChange={(e) => {
                setAgencyCode(e.target.value)
                getAttachments({
                    variables: {
                        filter: { agencyCode: e.target.value },
                        ...paginationState,
                        order: [['createdAt', 'DESC']],
                    }
                });
                setPaginationState({
                    limit: 10,
                    offset: 0,
                    currentPage: 1,
                    isReset: false,
                });
            }}
            value={agencyCode}
            data-testid='documentation-agency'
          />
        )}

        <div>
          <div className='agency-documentation'>
            <div className='attachments-title'>Agency documentation</div>
            <Button
              disabled={!displayBtnValidation || !agencyCode}
              data-testid='agency-file-upload'
              onClick={() => { uploadNewFileModal.openModal() }}
              type='button'
              variant='outline'
              leftIcon={{ name: 'file_upload' }}
              label='Upload file'
            />
          </div>
          {attachmentTypes?.getAllAttachmentTypes && (
            <div className='doc-table'> 
              <AFPTable
                testId='documentation-table'
                columns={columns}
                data={attachments?.rows || []}
              />
              {attachments?.rows?.length > 0 && (
                <Pagination
                  buttonVariant='primary'
                  variant='advanced'
                  itemsCount={attachments?.count}
                  currentPage={paginationState.currentPage}
                  itemsPerPage={paginationState.limit}
                  onPageChange={handlePaginationChange}
                />
              )}
            </div>
          )}
          {!attachments?.rows?.length && (
            <EmptyState
              hasBackground
              containerStyles='margin-top-neg-2 padding-y-10'
              bottomText='No files uploaded'
            />)}
        </div>

        <DisplayUploadFileModal
          isOpen={uploadNewFileModal.isOpen}
          handleClose={uploadNewFileModal.closeModal}
          getAttachments={getAttachments}
        />
        <DisplayDeleteFileModal
          isOpen={deleteFileModal.isOpen}
          handleClose={closeFileDelete}
          handleDelete={handleFileDelete}
        />
      </div>
    );
};

export default DocumentationManagementListing;
