/* eslint-disable import/prefer-default-export */

export const StoreSubjects = {
  All: 'all',
  Requisition: 'Requisition',
  Order: 'Order',
  OrderVehicle: 'OrderVehicle',
  OrderLine: 'OrderLine',
  OrderActivityComment: 'OrderActivityComment',
  OrderActivityAttachment: 'OrderActivityAttachment',
  OrderActivityCustomerAttachment: 'OrderActivityCustomerAttachment',
  OrderActivityEvent: 'OrderActivityEvent',
  OrderActivityVendorAttachment: 'OrderActivityVendorAttachment',
  OrderActivityVendorComment: 'OrderActivityVendorComment',
  OrderStatusSubject: 'OrderStatusSubject',
  RequisitionActivityComment: 'RequisitionActivityComment',
  RequisitionActivityEvent: 'RequisitionActivityEvent',
  Solicitation: 'Solicitation',
  VehicleStatus: 'VehicleStatus',
  BidReport: 'BidReport',
  Vendor: 'Vendor',
  StandardItem: 'StandardItem',
  EquipmentCodes: 'EquipmentCodes',
  CatalogCodes: 'CatalogCodes',
  FederalVehicleStandards: 'FederalVehicleStandards',
  Requirements: 'Requirements',
  PSOQuestionnaire: 'PSOQuestionnaire',
  FVSComments: 'FVSComments',
  MakeModel: 'MakeModel',
  VendorAssociatedLocation: 'VendorAssociatedLocation',
  Rental: 'Rental',
  Lease: 'Lease',
  LeasingRequisition: 'LeasingRequisition',
  LeasingNonStdReq: 'LeasingNonStdReq',
};

export const StoreOperations = {
  View: 'view',
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Approve: 'approve',
  Manage: 'manage',
  EngineerReview: 'engineerReview',
  updateStatus: 'updateStatus',
  updateReceiptDate: 'updateReceiptDate',
  onBehalfOrder: 'onBehalfOrder',
};

export const canViewRequisitionAndOrders = (ability) => ability?.can(StoreOperations.View, StoreSubjects.Requisition) && ability?.can(StoreOperations.View, StoreSubjects.Order)

export const canCreateRequisition = (ability) => ability?.can(StoreOperations.Create, StoreSubjects.Requisition)

export const canCreateLeaseVehicle = (ability) => ability?.can(StoreOperations.Create, StoreSubjects.LeasingRequisition)
