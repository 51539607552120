import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import {
    GET_AWS_SIGNED_URL,
    CREATE_DOCUMENTATION_ATTACHMENT,
    GET_ALL_ATTACHMENT_TYPES,
    DELETE_ATTACHMENT,
    GET_ATTACHMENTS
} from "../../components/Attachment/helpers";
import { GET_AGENCIES_BY_PERMISSION } from '../../services/data-layer';

export const DocumentManagementContext = createContext();

const initialState = {
    agencyCode: '',
    attachments: { rows: [], hasMore: false, count: 0 },
    paginationState: {
        limit: 10,
        offset: 0,
        currentPage: 1,
        isReset: false,
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_AGENCY_CODE':
            return { ...state, agencyCode: action.payload };
        case 'SET_ATTACHMENTS':
            return { ...state, attachments: action.payload };
        case 'SET_PAGINATION_STATE':
            return { ...state, paginationState: action.payload };

        default:
            return { ...state };
    }
};

function DocumentManagementProvider({ children, ...props }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const setAttachmentData = (data) => { dispatch({ type: 'SET_ATTACHMENTS', payload: data }) };
    const setPaginationState = (data) => { dispatch({ type: 'SET_PAGINATION_STATE', payload: data }) };
    const setAgencyCode = (data) => { dispatch({ type: 'SET_AGENCY_CODE', payload: data }) };

    const { data: attachmentTypes } = useQuery(GET_ALL_ATTACHMENT_TYPES);
    const [getSignedURL] = useMutation(GET_AWS_SIGNED_URL);
    const [createAttachment] = useMutation(CREATE_DOCUMENTATION_ATTACHMENT);
    const [getAttachments] = useLazyQuery(
        GET_ATTACHMENTS,
        {
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                if (data?.getAttachments) {
                    const attachmentResponse = data?.getAttachments;
                    setAttachmentData({ ...attachmentResponse });
                }
            }
        },
    );
    const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
        onCompleted: () => {
            getAttachments({
                variables: {
                    filter: { agencyCode: state.agencyCode },
                    limit: 10,
                    offset: 0,
                    order: [['createdAt', 'DESC']],
                },
            });
        },
    });

    const { data: agencies } = useQuery(GET_AGENCIES_BY_PERMISSION, {
        variables: { operation: 'view', subject: 'OrganizationProfile', order: [['agencyCode', 'ASC']] },
    });

    return (
      <DocumentManagementContext.Provider
        value={{
            ...state,
            deleteAttachment,
            dispatch,
            setAgencyCode,
            getAttachments,
            agencies,
            attachmentTypes,
            getSignedURL,
            createAttachment,
            setPaginationState,
            ...props,
        }}
      >
        {children}
      </DocumentManagementContext.Provider>
    );
}

export default DocumentManagementProvider;

DocumentManagementProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export const useDocumentManagement = () => useContext(DocumentManagementContext);
