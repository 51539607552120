import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useUserFilter } from '../providers';
import { getSelectedFilterValue } from '../../../utilities/filterUtils';

const {
  FilterPanelField,
  FilterPanelFilterShape,
  useFilterPanel,
} = FilterPanel;

const placeholderOption = {
  value: '',
  label: 'Select bureau',
  defaultValue: true,
};

const BureauFilterItem = ({ filter }) => {
  const {
    getFilterFromState,
    mergedFilters,
    clearOneFilter,
  } = useFilterPanel();

  const { getBureaus, bureaus, filters } = useUserFilter();
  const selectedValue = getSelectedFilterValue(filters, filter) || '';

  const [options, setOptions] = useState([placeholderOption]);
  const [agencyCode, setAgencyCode] = useState('');

  useEffect(() => {
    const agencyFilter = getFilterFromState(
      '$customerAttrs.registered_agency_code$',
    );
    setAgencyCode(agencyFilter.value || '');
  }, [mergedFilters]);

  useEffect(() => {
    // clear bureau when agency is changed
    clearOneFilter(filter);

    // eslint-disable-next-line no-unused-expressions
    agencyCode
      ? getBureaus({ variables: { agencyCode,  order: [['id', 'asc']] } })
      : setOptions([placeholderOption]);
  }, [agencyCode]);

  useEffect(() => {
    setOptions([
      placeholderOption,
      ...bureaus.map((bureau) => ({
        value: bureau.id,
        label: bureau.name,
      })),
    ]);
  }, [bureaus]);

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
          value: selectedValue,
        }}
      />
    </>
  );
};

BureauFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default BureauFilterItem;
