import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const DeleteDocumentModal = ({
  handleClose,
  handleDelete,
}) => {
  return (
    <Modal
      data-testid="delete-modal"
      title={<h2>Delete document</h2>}
      onClose={() => {handleClose()}}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            data-testid="cancel-button"
            onClick={() => handleClose()}
            label="Cancel"
          />
          <Button
            type="button"
            variant="secondary"
            data-testid="delete-button"
            onClick={() => handleDelete()}
            label="Delete document"
          />
        </>
      }
    >
      <div>Are you sure you want to delete this document? This action cannot be undone.</div>
    </Modal>
  );
};

DeleteDocumentModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteDocumentModal;