import React from 'react';
import { Link } from 'react-router-dom';
import {
  PageTitle,
  Breadcrumbs,
} from '@gsa/afp-component-library';
import DocumentationManagementListing from './documents-management-listing';
import DocumentManagementProvider from './documentation-management-provider';
import './documents-management.scss'

const DocumentationManagement = () => {
  return (
    <div className='grid-col'>
      <DocumentManagementProvider>
        <>
          <Breadcrumbs
            trail={[
              <Link style={{ textDecoration: 'underline' }} to='/home'>Home</Link>,
              <Link style={{ textDecoration: 'underline' }} to='/admin/organization-profile'>Organization Profile</Link>
            ]}
            current='Document Management'
          />
          <PageTitle title='Documentation Management' />
          <DocumentationManagementListing />
        </>
      </DocumentManagementProvider>
    </div>
  );
};

export default DocumentationManagement;
